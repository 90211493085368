import Component from './Component';
import HeroVideo from './Hero/HeroVideo';
import HeroInfo from './Hero/HeroInfo';

class Hero extends Component {
  constructor(_element) {
    super(_element);

    const infoElements = _element.querySelectorAll('.hero__info');
    const infoInstances = [];

    Array.from(infoElements).forEach((infoElement) => {
      infoInstances.push(new HeroInfo(this, infoElement));
    });
    this.containerElement = _element.querySelector('.hero__container');
    this.video = new HeroVideo(this);
    this.drawingInstances = [this.video, ...infoInstances];
  }

  draw() {
    if (this.elementIsInView()) {
      this.containerElement.style.display = '';
      this.drawingInstances.forEach((instance) => instance.draw());
    } else {
      this.containerElement.style.display = 'none';
    }
  }
}

Array.from(document.querySelectorAll('.hero')).forEach((_element) => {
  new Hero(_element);
});
