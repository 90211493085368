[...document.querySelectorAll('.parade')].forEach((element) => {
  const div1Element = element.querySelector('div');
  const div2Element = element.querySelector('div div');
  div2Element.animate({
    translate: [`${div1Element.offsetWidth}px 0`, '-100% 0'],
  }, {
    duration: 80000,
    easing: 'linear',
    directions: 'alternate-reverse',
    iterations: Infinity,
  });
});
