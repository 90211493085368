import { Power1, TweenLite } from 'gsap/all';

const { HELPER } = window;

class SlideInItem {
  constructor(_parentInstance, _element, _index) {
    this.parentInstance = _parentInstance;
    this.element = _element;
    this.index = _index;
    this.draw();
  }

  draw() {
    let x = (HELPER.scrollY - this.parentInstance.elementOffsetTop + (window.innerHeight - this.parentInstance.elementOffsetHeight * 0.9)) * -1;
    x = x < 0 ? 0 : x;
    x = Math.pow(x, this.index * 0.02 + 1);
    const tweenOptions = {
      x: `${x}px`,
      ease: Power1.easeInOut,
    };
    TweenLite.to(this.element, 0.2, tweenOptions);
  }
}

export default SlideInItem;
