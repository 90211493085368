import Component from './Component';
import FloatingBoxesItem from './FloatingBoxes/FloatingBoxesItem';

class FloatingBoxes extends Component {
  constructor(_element) {
    super(_element);

    const itemElements = _element.querySelectorAll('.floating-boxes__item');

    this.itemInstances = [];

    Array.from(itemElements).forEach((itemElement) => {
      this.itemInstances.push(new FloatingBoxesItem(this, itemElement));
    });
  }

  draw() {
    if (this.elementIsInView()) {
      this.itemInstances.forEach((instance) => instance.draw());
    }
  }
}

Array.from(document.querySelectorAll('.floating-boxes')).forEach((_element) => {
  new FloatingBoxes(_element);
});
