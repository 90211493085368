import { Power1, TweenLite } from 'gsap/all';

class SlideInCenterImage {
  constructor(_element, _index) {
    this.element = _element;
    this.index = _index;
  }

  draw(x) {
    x = this.index === 0 ? x * -1 : x;
    const tweenOptions = {
      x: `${x}px`,
      ease: Power1.easeInOut,
    };
    TweenLite.to(this.element, 0.2, tweenOptions);
  }
}

export default SlideInCenterImage;
