import Component from './Component';
import DetailsInfo from './Details/DetailsInfo';
import DetailsImageSlideIn from './Details/DetailsImageSlideIn';

class Details extends Component {
  constructor(_element) {
    super(_element);

    const infoElements = _element.querySelectorAll('.details__info');

    this.imageElement = _element.querySelector('.details__image');
    this.imageSlideInElement = _element.querySelector('.details__image-slide-in');
    this.infoInstances = [];
    this.imageSlideInInstance;

    Array.from(infoElements).forEach((infoElement) => {
      this.infoInstances.push(new DetailsInfo(this, infoElement));
    });

    if (this.imageSlideInElement) {
      this.imageSlideInInstance = new DetailsImageSlideIn(this, this.imageSlideInElement);
    }
  }

  draw() {
    if (this.elementIsInView()) {
      this.infoInstances.forEach((instance) => instance.draw());
      if (this.imageSlideInInstance) {
        this.imageSlideInInstance.draw();
      }
    }
  }
}

Array.from(document.querySelectorAll('.details')).forEach((_element) => {
  new Details(_element);
});
