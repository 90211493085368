import Component from './Component';
import SlideInCenterImage from './SlideInCenter/SlideInCenterImage';
import SlideInCenterText from './SlideInCenter/SlideInCenterText';

const { HELPER } = window;

class SlideInCenter extends Component {
  constructor(_element) {
    super(_element);

    const itemElements = _element.querySelectorAll('.slide-in-center__image');
    const textElement = _element.querySelector('.slide-in-center__text');

    this.itemInstances = [];

    Array.from(itemElements).forEach((itemElement, index) => {
      this.itemInstances.push(new SlideInCenterImage(itemElement, index));
    });

    if (textElement) {
      this.text = new SlideInCenterText(this, textElement);
    }

    this.draw(true);
  }

  draw(init = false) {
    if (this.elementIsInView() || init === true) {
      let x = (HELPER.scrollY - this.elementOffsetTop + (window.innerHeight - this.elementOffsetHeight * 0.8)) * -0.2;
      x = x < 0 ? 0 : x;
      this.itemInstances.forEach((instance) => instance.draw(x));
      if (this.text) {
        this.text.draw(x);
      }
    }
  }
}

Array.from(document.querySelectorAll('.slide-in-center')).forEach((_element) => {
  new SlideInCenter(_element);
});
