import { Power1, TweenLite } from 'gsap/all';

const { HELPER } = window;

class DetailsImageSlideIn {
  constructor(_parentInstance, _element) {
    this.parentInstance = _parentInstance;
    this.element = _element;
    this.isSlidedIn = false;
  }

  draw() {
    let y = (HELPER.scrollY - this.parentInstance.elementOffsetTop + this.parentInstance.elementOffsetHeight * 0.2) * 0.03;
    y = y > 0 ? 0 : y;
    this.isSlidedIn = y === 0;
    // this.element.style.transform = `translateY(${y / 16}rem)`;
    const tweenOptions = {
      y: `${y}rem`,
      ease: Power1.easeOut,
    };
    TweenLite.to(this.element, 0.1, tweenOptions);
  }
}

export default DetailsImageSlideIn;
