import Component from './Component';
import BrowserText from './Browser/BrowserText';

const { HELPER } = window;

class Browser extends Component {
  constructor(_element) {
    super(_element);

    function onScroll() {
      self.barOffsetTop = 1 - (self.elementOffsetTop - mainnavUlHeight) / (HELPER.scrollY);
    }

    const self = this;
    const mainnavUlHeight = document.querySelector('.main-nav ul').offsetHeight - 1;
    const textElement = _element.querySelector('.browser__text');
    const barElement = _element.querySelector('.browser__bar');

    self.contentElement = _element.querySelector('.browser__content');
    self.barElement = barElement;
    self.barOffsetTop = 0;
    self.instances.push(new BrowserText(this, textElement));

    window.addEventListener('scroll', onScroll, {
      passive: true,
    });
  }

  draw() {
    if (this.elementIsInView()) {
      if (this.barOffsetTop) {
        const translateY = Math.pow(this.barOffsetTop * 12, 1.1) * -100;
        this.contentElement.style.display = '';
        this.contentElement.style.willChange = 'transform, filter';
        this.contentElement.style.transform = `translateY(${translateY}px)`;
        this.instances.forEach((instance) => instance.draw());
      } else {
        this.contentElement.style.display = 'none';
        this.contentElement.style.willChange = '';
        this.contentElement.style.transform = '';
      }
    } else {
      this.contentElement.style.display = 'none';
      this.contentElement.style.willChange = '';
      this.contentElement.style.transform = '';
    }
  }
}

[...document.querySelectorAll('.browser')].forEach((element) => new Browser(element));
