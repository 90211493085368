const { HELPER } = window;

class DetailsInfo {
  constructor(_parentInstance, _element) {
    const self = this;
    let showAfterImageSlidein = false;

    function onMousemove(event) {
      self.mouseX = event.clientX;
      self.mouseY = event.clientY;
    }

    if (_element.dataset.timeShow === 'image-slide-in') {
      showAfterImageSlidein = true;
    }

    window.addEventListener('mousemove', onMousemove);

    this.element = _element;
    this.parentInstance = _parentInstance;
    this.isVisible = false;
    this.randomMultiplier = 1 + Math.random() * 2;
    this.showAfterImageSlidein = showAfterImageSlidein;
  }

  _elementIsInView() {
    const offsetTop = this.parentInstance.elementOffsetTop
                  + this.element.offsetTop;
    const isInView = (HELPER.scrollY + window.innerHeight > offsetTop + this.element.offsetHeight + 16);
    return isInView;
  }

  _show() {
    this.element.classList.add('is-visible');
    this.isVisible = true;
  }

  _hide() {
    this.element.classList.remove('is-visible');
    this.isVisible = false;
  }

  draw() {
    const { parentInstance } = this;
    if (this._elementIsInView()) {
      this._show();
      if (this.showAfterImageSlidein) {
        const { imageSlideInInstance } = parentInstance;
        if (imageSlideInInstance?.isSlidedIn) {
          this._show();
        } else {
          this._hide();
        }
      }
    } else {
      this._hide();
    }
    // let scrollingCenterY = HELPER.scrollY + window.innerHeight / 2;
    // let elementCenterY   = this.parentInstance.elementOffsetTop + this.parentInstance.elementOffsetHeight / 2;
    // let x = (this.mouseX / window.innerWidth - 0.5) * this.randomMultiplier;
    // let y = (this.mouseY / window.innerHeight - 0.5) * this.randomMultiplier;
    // y = y + (scrollingCenterY - elementCenterY) * 0.01;
    // this.element.style.transform = `translate(${x / 16}rem, ${y / 16}rem)`;
  }
}


export default DetailsInfo;
