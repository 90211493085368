const { HELPER } = window;

class Component {
  constructor(_element) {
    const component = this;
    this.element = _element;
    if (Object.prototype.toString.call(window.COMPONENTS) !== '[object Array]') {
      window.COMPONENTS = [];
    }
    window.COMPONENTS.push(this);

    this.instances = [];
    this.onResize();
    window.addEventListener('load', () => {
      component.onResize();
    });
  }

  onResize() {
    this.elementOffsetTop = this.element.offsetTop + HELPER.mainOffsetTop;
    this.elementOffsetHeight = this.element.offsetHeight;
    this.elementCenterY = this.elementOffsetTop + this.elementOffsetHeight / 2;
    this.instances.forEach((instance) => {
      if (typeof instance.onResize === 'function') {
        instance.onResize();
      }
    });
  }

  elementIsInView() {
    const isInView = (HELPER.scrollY + window.innerHeight + 100 > this.elementOffsetTop
                    && HELPER.scrollY - 100 < this.elementOffsetTop + this.elementOffsetHeight);
    return isInView;
  }
}

export default Component;
