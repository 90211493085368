const { HELPER } = window;

class BrowserText {
  constructor(_parentInstance, _element) {
    this.parentInstance = _parentInstance;
    this.element = _element;
    this.offsetTop = _element.offsetTop + _parentInstance.elementOffsetTop;

    this.onResize();
  }

  onResize() {
    this.elementOffsetTop = this.element.offsetTop + HELPER.mainOffsetTop + this.parentInstance.elementOffsetTop;
  }

  draw() {
    if (HELPER.scrollY + HELPER.windowInnerHeight > this.elementOffsetTop) {
      const opacity = (1 - this.elementOffsetTop / (HELPER.scrollY + HELPER.windowInnerHeight)) * 40;
      const contentOpacity = 1 - opacity * 0.15;
      const blur = opacity * 3;
      this.element.style.opacity = opacity;
      this.parentInstance.contentElement.style.opacity = contentOpacity;
      this.parentInstance.contentElement.style.filter = `blur(${blur}px)`;
    } else {
      this.element.style.opacity = '0';
      this.parentInstance.contentElement.style.opacity = '';
      this.parentInstance.contentElement.style.filter = '';
    }
  }
}

export default BrowserText;
