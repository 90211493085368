// or all tools are exported from the "all" file (excluding members-only plugins):
import { gsap, CSSPlugin } from 'gsap/all';

import './Helper';
import './components/Component';
import './components/Intro';
import './components/Hero';
import './components/FloatingBoxes';
import './components/Parade';
import './components/Pos';
import './components/Details';
import './components/SlideIn';
import './components/SlideInCenter';
import './components/Family';
import './components/Moreover';
import './components/Next';
import './components/ScrollZoom';
import './components/Browser';

// don't forget to register plugins
gsap.registerPlugin(CSSPlugin);

(function () {
  function getDrawingComponents() {
    function filterComponents(component) {
      const hasDrawFunction = typeof component.draw === 'function';
      return hasDrawFunction;
    }
    const drawingComponents = window.COMPONENTS.filter(filterComponents);
    return drawingComponents;
  }

  function draw() {
    for (const component of drawingComponents) {
      try {
        component.draw();
      } catch (exception) {
        console.log(exception);
      }
    }
    requestAnimationFrame(draw);
  }

  function onResize() {
    for (const component of window.COMPONENTS) {
      component.onResize();
    }
  }

  requestAnimationFrame(draw);

  window.addEventListener('resize', onResize);

  window.HELPER.onAllImagesLoaded(() => {
    window.COMPONENTS.forEach((component) => component.onResize());
  });

  const drawingComponents = getDrawingComponents();
}());
