import { Power4, TweenLite } from 'gsap/all';

const { HELPER } = window;

class PosText {
  constructor(_parentInstance, _element) {
    const spanElement = _element.querySelector('span');

    if (spanElement) {
      this.element = _element;
      this.spanElement = spanElement;
      this.posOffsetTop = _element.offsetTop + _parentInstance.elementOffsetTop - window.innerHeight;
      this.number = parseFloat(spanElement.innerText);

      spanElement.innerText = '0';
    }

    this.showHasRun = false;
  }

  _show() {
    const { spanElement } = this;
    const text = {
      num: 0,
    };
    function setText() {
      spanElement.innerText = Math.round(text.num);
    }
    TweenLite.to(text, 2, { num: this.number, onUpdate: setText, ease: Power4.easeOut });
    this.showHasRun = true;
  }

  draw() {
    if (this.element && !this.showHasRun && HELPER.scrollY > this.posOffsetTop) {
      this._show();
    }
  }
}

export default PosText;
