import { Power1, TweenLite } from 'gsap/all';
import Component from './Component';
import PosText from './Pos/PosText';

/*
Pos ≙ Point Of Sale
parallax for the background image
*/

const { HELPER } = window;

class Pos extends Component {
  constructor(_element) {
    super(_element);

    this.imageElement = _element.querySelector('.pos__image');
    this.textElement = _element.querySelector('.pos__text');
    if (this.textElement) {
      this.posTextInstance = new PosText(this, this.textElement);
    }
  }

  draw() {
    if (this.elementIsInView()) {
      const y = (1 - (HELPER.scrollCenterY - this.elementCenterY)) * -0.08;
      const tweenOptions = {
        y,
        ease: Power1.easeOut,
      };
      TweenLite.to(this.imageElement, 0.2, tweenOptions);
      if (this.posTextInstance) {
        this.posTextInstance.draw();
      }
    }
  }
}

Array.from(document.querySelectorAll('.pos')).forEach((_element) => {
  new Pos(_element);
});
