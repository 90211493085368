class Helper {
  constructor() {
    const _self = this;
    let lastScrollY = 0;
    const mainElement = document.querySelector('.main');

    function init() {
      updateScroll();
      updateWindowSizes();
      _self.updateMainSizes();
    }

    function updateScroll() {
      function getScrollY() {
        return window.pageYOffset;
      }
      _self._scrollY = getScrollY();
      if (_self._scrollY > lastScrollY) {
        _self._scrollDirection = -1;
      } else {
        _self._scrollDirection = 1;
      }
      lastScrollY = _self._scrollY;
    }

    function updateWindowSizes() {
      _self._windowInnerHeight = window.innerHeight;
      _self._windowInnerWidth = window.innerWidth;
    }

    function onScroll() {
      updateScroll();
    }

    function onResize() {
      updateWindowSizes();
      _self.updateMainSizes();
    }

    window.addEventListener('resize', () => onResize(), {
      passive: true,
    });
    window.addEventListener('scroll', () => onScroll(), {
      passive: true,
    });

    this.mainElement = mainElement;

    init();
  }

  updateMainSizes() {
    this._mainOffsetTop = this.mainElement.offsetTop;
    this._mainOffsetHeight = this.mainElement.offsetHeight;
  }

  get windowInnerHeight() {
    return this._windowInnerHeight;
  }

  get mainOffsetHeight() {
    return this._mainOffsetHeight;
  }

  get mainOffsetTop() {
    return this._mainOffsetTop;
  }

  get scrollY() {
    return this._scrollY;
  }

  get scrollCenterY() {
    return this._scrollY + window.innerHeight / 2;
  }

  get scrollDirection() {
    return this._scrollDirection; // -1 is down, 1 is up
  }

  onAllImagesLoaded(loaded) {
    const _self = this;
    const imageElements = document.querySelectorAll('img');
    let loadedImages = 0;

    function onLoad() {
      loadedImages += 1;
      if (loadedImages === imageElements.length) {
        console.log('all images loaded');
        loaded();
        _self.updateMainSizes();
      }
    }

    Array.from(imageElements).forEach((imageElement) => {
      if (imageElement.complete && imageElement.offsetWidth > 0) {
        onLoad();
      } else {
        imageElement.addEventListener('load', onLoad);
      }
    });
  }
}


window.HELPER = new Helper();
window.COMPONENTS = [];
