import Component from './Component';
import SlideInItem from './SlideIn/SlideInItem';
import SlideInText from './SlideIn/SlideInText';

class SlideIn extends Component {
  constructor(_element) {
    super(_element);

    const itemElements = _element.querySelectorAll('.slide-in__item');
    const textElement = _element.querySelector('.slide-in__text');

    this.itemInstances = [];

    Array.from(itemElements).forEach((itemElement, index) => {
      this.itemInstances.push(new SlideInItem(this, itemElement, index));
    });

    if (textElement) {
      this.text = new SlideInText(this, textElement);
    }
  }

  draw() {
    if (this.elementIsInView()) {
      this.itemInstances.forEach((instance) => instance.draw());
      if (this.text) {
        this.text.draw();
      }
    }
  }
}

Array.from(document.querySelectorAll('.slide-in')).forEach((_element) => {
  new SlideIn(_element);
});
