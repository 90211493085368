const { HELPER } = window;

class SlideInText {
  constructor(_parentInstance, _element) {
    this.parentInstance = _parentInstance;
    this.element = _element;
  }

  draw() {
    let x = (HELPER.scrollY - this.parentInstance.elementOffsetTop + (window.innerHeight - this.parentInstance.elementOffsetHeight)) * -1;
    x = x < 0 ? 0 : x;
    if (x > 0) {
      const opacity = 1 - x / 80 + 1 / 80;
      this.element.style.opacity = opacity;
    } else {
      this.element.style.opacity = '';
    }
  }
}

export default SlideInText;
