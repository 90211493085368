import { Power1, TweenLite } from 'gsap/all';

const { HELPER } = window;

class NextImage {
  constructor(_parentInstance, _element) {
    if (_element.dataset.position) {
      try {
        const position = JSON.parse(_element.dataset.position);
        this.rotate = position.rotate;
        this.direction = position.direction;
        _element.style.transform = `rotate(${this.rotate}deg)`;
      } catch (exception) {
        console.info(exception);
      }
    }

    this.element = _element;
    this.parentInstance = _parentInstance;
  }

  draw(init = false) {
    const duration = init ? 0 : 0.2;
    const offsetTop = HELPER.mainOffsetTop
                  + HELPER.mainOffsetHeight;
    let elementCenterY = offsetTop + this.parentInstance.elementOffsetHeight / 2;
    if (this.parentInstance.element.classList.contains('next--home')) {
      elementCenterY = this.parentInstance.elementCenterY;
    }
    const y = (HELPER.scrollCenterY - elementCenterY) * 0.01;
    if (this.rotate && this.direction) {
      const tweenOptions = {
        y: `${y}%`,
        rotation: `${this.rotate + y * 2 * this.direction}deg`,
        ease: Power1.easeOut,
      };
      TweenLite.to(this.element, duration, tweenOptions);
    } else {
      const tweenOptions = {
        y: `${y}%`,
        ease: Power1.easeOut,
      };
      TweenLite.to(this.element, duration, tweenOptions);
    }
  }
}

export default NextImage;
