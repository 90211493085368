import { Power1, TweenLite } from 'gsap/all';

const { HELPER } = window;

class IntroImage {
  constructor(_parentInstance, _element, _index) {
    function init() {
      const x = _self.getX(0.04, 80);
      const tweenOptions = {
        x: `${x}px`,
      };
      _element.style.transitionDuration = '0s';
      _element.style.opacity = '0';
      TweenLite.to(_element, 0, tweenOptions);
      _self.isHidden = false;
      requestAnimationFrame(() => {
        _element.style.transitionDuration = '';
      });
    }

    const _self = this;

    this.parentInstance = _parentInstance;
    this.element = _element;
    this.isStartAnimationPlayed = false;
    this.isHidden = true;
    this.index = _index;

    this.draw();

    init();
  }

  startAnimation() {
    this.element.style.opacity = '';
    const x = this.getX();
    const tweenOptions = {
      x: `${x}px`,
      ease: Power1.easeOut,
      onComplete: () => {
        this.isStartAnimationPlayed = true;
      },
    };
    TweenLite.to(this.element, 1, tweenOptions);
  }

  hide() {
    const x = this.getX(0.04, 80);
    const tweenOptions = {
      x: `${x}px`,
      ease: Power1.easeInOut,
      delay: 0.5,
    };
    TweenLite.to(this.element, 0.8, tweenOptions);
    this.isHidden = true;
  }

  show() {
    this.isStartAnimationPlayed = true;
    this.isHidden = false;
  }

  getX(multiplier = 0.02, addition = 0) {
    let x = (HELPER.scrollY - this.parentInstance.elementOffsetTop - this.parentInstance.elementOffsetHeight * 0.08) * -1 + addition;
    x = x < 0 ? 0 : x;
    x = Math.pow(x, this.index * multiplier + 1);
    return x;
  }

  draw() {
    if (this.isStartAnimationPlayed == true && this.isHidden === false) {
      const x = this.getX();
      const tweenOptions = {
        x: `${x}px`,
        ease: Power1.easeInOut,
      };
      TweenLite.to(this.element, 0.2, tweenOptions);
    }
  }
}

export default IntroImage;
