import Component from './Component';
import IntroImage from './Intro/IntroImage';

const { COMPONENTS } = window;

class Intro extends Component {
  constructor(_element) {
    super(_element);

    function init() {
      if (location.hash === '#!mehr') {
        _self.element.classList.add('show-text-more');
      } else {
        textMoreElement.style.display = 'none';
      }
      _self.onResize();
      [...imgElements].forEach((imgElement, index) => {
        _self.imageInstances.push(new IntroImage(_self, imgElement, index));
        if (imgElement.complete && imgElement.offsetWidth > 0) {
          setTimeout(onImageLoad, 100);
        } else {
          imgElement.addEventListener('load', onImageLoad);
        }
      });
    }

    function onImageLoad() {
      loadedImages += 1;
      if (loadedImages === imageElements.length) {
        _self.imagesLoaded = true;
        if (location.hash !== '#!mehr') {
          _self.imageInstances.forEach((instance) => instance.startAnimation());
        }
      } else {
        _self.imageInstances.forEach((instance) => {
          instance.element.style.opacity = '';
        });
      }
    }

    function onHashchange() {
      if (location.hash === '#!mehr') {
        _self.showTextMore();
      } else {
        _self.hideTextMore();
      }
    }

    const _self = this;
    const imageElements = _element.querySelectorAll('.intro__image');
    const imgElements = _element.querySelectorAll('.intro__image img'); // has to be img element!
    const bttnMoreElement = _element.querySelector('.intro__bttn-more');
    const textElement = _element.querySelector('.intro__text');
    const textMoreElement = _element.querySelector('.intro__text-more');
    let loadedImages = 0;

    this.imageInstances = [];
    this.isTextMoreVisible = false;
    this.imagesLoaded = false;
    this.bttnMoreElement = bttnMoreElement;
    this.textElement = textElement;
    this.textMoreElement = textMoreElement;

    window.addEventListener('hashchange', onHashchange);

    init();
  }

  resizeAllComponents() {
    for (const component of COMPONENTS) {
      component.onResize();
    }
  }

  showTextMore() {
    function onTransitionend(event) {
      if (event.propertyName === 'height') {
        _self.element.removeEventListener('transitionend', onTransitionend);
        if (_self.isTextMoreVisible === true) {
          _self.element.style.height = '';
          _self.resizeAllComponents();
        }
      }
    }

    const _self = this;
    _self.isTextMoreVisible = true;

    if (!matchMedia('(max-width: 37.5em)').matches) {
      _self.standardHeight = this.elementOffsetHeight;
      _self.element.style.height = `${this.standardHeight}px`;
      _self.textMoreElement.style.display = '';
      _self.element.offsetWidth;
      _self.element.style.height = `${_self.textMoreElement.offsetHeight}px`;

      _self.element.removeEventListener('transitionend', onTransitionend);
      _self.element.addEventListener('transitionend', onTransitionend);
    } else {
      _self.textMoreElement.style.display = '';
      document.scrollingElement.scrollTop = 0;
      _self.resizeAllComponents();
    }

    _self.element.classList.add('show-text-more');
    _self.imageInstances.forEach((imageInstance) => {
      imageInstance.hide();
    });
  }

  hideTextMore() {
    function onTextMoreTransitionend() {
      _self.textMoreElement.removeEventListener('transitionend', onTextMoreTransitionend);
      if (_self.isTextMoreVisible === false) {
        _self.imageInstances.forEach((imageInstance) => {
          imageInstance.show();
        });
        _self.textMoreElement.style.display = 'none';
        _self.resizeAllComponents();
      }
    }

    const _self = this;
    _self.isTextMoreVisible = false;

    if (!matchMedia('(max-width: 37.5em)').matches) {
      _self.element.style.height = `${_self.element.offsetHeight}px`;
      _self.element.style.height = getComputedStyle(_self.element).minHeight;

      _self.textMoreElement.removeEventListener('transitionend', onTextMoreTransitionend);
      _self.textMoreElement.addEventListener('transitionend', onTextMoreTransitionend);
    } else {
      onTextMoreTransitionend();
    }

    _self.element.classList.remove('show-text-more');
  }

  draw() {
    if (this.elementIsInView()) {
      this.imageInstances.forEach((instance) => instance.draw());
    }
  }
}

Array.from(document.querySelectorAll('.intro')).forEach((_element) => {
  new Intro(_element);
});
