import Component from './Component';

class Family extends Component {
  constructor(_element) {
    super(_element);

    this.imageElement = _element.querySelector('.family__image');
  }

  draw() {
    if (this.elementIsInView()) {
      // let scrollingCenterY = HELPER.scrollY + window.innerHeight / 2;
      // let y = (scrollingCenterY - this.elementCenterY) * -0.02;
      // this.imageElement.style.transform = `translateY(${y / 16}rem)`;
    }
  }
}

Array.from(document.querySelectorAll('.family')).forEach((_element) => {
  new Family(_element);
});
