import Component from './Component';
import NextImage from './Next/NextImage';

const { HELPER } = window;

class Next extends Component {
  constructor(_element) {
    super(_element);

    this.imageElements = _element.querySelectorAll('.next__image');
    this.imageInstances = [];

    Array.from(this.imageElements).forEach((imageElement) => {
      const imageInstance = new NextImage(this, imageElement);
      this.imageInstances.push(imageInstance);
      imageInstance.draw(true);
    });
  }

  elementIsInView() {
    if (this.element.classList.contains('next--home')) {
      const isInView = (HELPER.scrollY + window.innerHeight + 100 > this.elementOffsetTop
                      && HELPER.scrollY - 100 < this.elementOffsetTop + this.elementOffsetHeight);
      return isInView;
    }
    const offsetTop = HELPER.mainOffsetTop
                    + HELPER.mainOffsetHeight;
    const isInView = (HELPER.scrollY + window.innerHeight + 100 > offsetTop);
    return isInView;
  }

  draw() {
    if (this.elementIsInView()) {
      this.imageInstances.forEach((imageInstance) => {
        imageInstance.draw();
      });
    }
  }
}

Array.from(document.querySelectorAll('.next')).forEach((_element) => {
  new Next(_element);
});
