import { Power1, TweenLite } from 'gsap/all';
import Component from './Component';

const { HELPER } = window;

class ScrollZoom extends Component {
  constructor(_element) {
    super(_element);

    const self = this;

    function onLoad() {
      self.imgElement.removeEventListener('load', onLoad);
      setTimeout(startAnimation, 100);
    }

    function startAnimation() {
      const tweenOptions = {
        opacity: 1,
        scale: 2,
        ease: Power1.easeOut,
        onComplete: () => {
          self.isLoaded = true;
        },
      };
      if (HELPER.scrollY === 0) {
        console.log('startAnimation');
        TweenLite.to(self.imgElement, 1, tweenOptions);
      } else {
        self.imgElement.style.opacity = '1';
        self.isLoaded = true;
      }
    }

    this.imageElement = _element.querySelector('.scroll-zoom__image');
    this.imgElement = _element.querySelector('.scroll-zoom__image img');

    this.isLoaded = false;
    this.imgElement.style.opacity = '0';
    this.imgElement.style.transform = 'scale(1.9)';

    if (this.imgElement.complete && this.imgElement.offsetWidth > 0) {
      window.requestAnimationFrame(onLoad);
    } else {
      this.imgElement.addEventListener('load', onLoad);
    }
  }

  draw() {
    if (this.elementIsInView()) {
      this.imageElement.style.display = '';
      if (this.isLoaded) {
        const relative = (HELPER.scrollY / (this.elementOffsetTop + this.elementOffsetHeight - HELPER.windowInnerHeight * 0.75));
        let zoom = 2 - Math.sin(relative * (0.5 * Math.PI));
        const blur = relative * 5 - 5;
        const brightness = 1 - (1 - relative) * -0.1;
        if (relative > 1) {
          this.imageElement.style.filter = `blur(${blur}px) brightness(${brightness})`;
        } else {
          this.imageElement.style.filter = '';
        }
        if (relative < 0) zoom = 2;
        if (relative > 1) zoom = 1;
        const tweenOptions = {
          scale: zoom,
          opacity: 1,
          ease: Power1.easeInOut,
        };
        TweenLite.to(this.imgElement, 0.125, tweenOptions);
      }
    } else {
      this.imageElement.style.display = 'none';
    }
  }
}

[...document.querySelectorAll('.scroll-zoom')].forEach((element) => new ScrollZoom(element));
