import { Power1, TweenLite } from 'gsap/all';

const { HELPER } = window;

class HeroVideo {
  constructor(_parentInstance) {
    const self = this;
    const heroElement = _parentInstance.element;
    const heroContainerElement = _parentInstance.containerElement;
    const element = heroElement.querySelector('.hero__video');
    const posterImage = new Image();
    posterImage.src = element.poster;

    function onCanPlayThrough() {
      setTimeout(() => {
        if (self.canplaythrough === false) {
          // self.pause();
          element.pause();
          self.canplaythrough = true;
          self._startAnimation();
          console.info('can play through');
        }
      }, 100);
    }

    function onPosterLoaded() {
      self._showVideo();
    }

    this.parentInstance = _parentInstance;
    this.heroElement = heroElement;
    this.heroContainerElement = heroContainerElement;
    this.element = element;
    this.canplaythrough = false;
    this.isVisible = false;
    this.isStartAnimationPlayed = false;
    this.isRotationEnded = false;
    this.potentialCurrentTime = 0;

    element.classList.add('is-hidden');
    element.pause();
    element.currentTime = 0.27; // 9th frame: 9 * 30/1000 = 0.27

    element.addEventListener('canplaythrough', onCanPlayThrough);
    posterImage.addEventListener('load', onPosterLoaded);

    setTimeout(onCanPlayThrough, 2000); // chrome fix
  }

  _startAnimation() {
    const self = this;
    const playTo = this._convertScrollingToTime();
    const { element } = this;

    function onComplete() {
      self.isStartAnimationPlayed = true;
    }

    element.currentTime = 0.27; // 9th frame: 9 * 30/1000 = 0.27
    if (HELPER.scrollY === 0) {
      TweenLite.to(element, element.currentTime, {
        currentTime: playTo, ease: Power1.easeOut, onComplete, delay: 1,
      });
    } else {
      onComplete();
    }

    this._showVideo();
  }

  _showVideo() {
    if (!this.isVisible) {
      this.element.style.transitionDuration = '1s';
      this.isVisible = true;
      setTimeout(() => {
        this.element.classList.remove('is-hidden');
        this.element.offsetHeight;
        this.element.style.transitionDuration = '';
      }, 200);
    }
  }

  _convertScrollingToTime() {
    const { parentInstance } = this;
    const percent = HELPER.scrollY / (parentInstance.elementOffsetHeight - window.innerHeight * 1.6);
    const time = percent * this.element.duration;
    return time;
  }

  _getAnimationDuration(playTo) {
    return Math.sqrt(Math.abs(this.element.currentTime - playTo)) * 0.15;
  }

  _rotate() {
    const self = this;
    const playTo = self._convertScrollingToTime();
    const animationDuration = this._getAnimationDuration(playTo);
    const blur = (playTo - 2.4) * 2 > 0 ? (playTo - 2.4) * 2 : 0;
    const scale = (playTo - 2.4) * -0.02 + 1 < 1 ? (playTo - 2.4) * -0.02 + 1 : 1;
    const brightness = (playTo - 2.4) * -0.1 + 1 < 1 ? (playTo - 2.4) * -0.1 + 1 : 1;
    const tweenOptions = {
      currentTime: playTo,
      ease: Power1.easeInOut,
      onUpdate() {
        self.potentialCurrentTime = this.vars.currentTime;
      },
    };
    TweenLite.to(this.element, animationDuration, tweenOptions);
    if (blur > 0) {
      // this.element.style.filter = `blur(${blur}px)`;
    } else {
      this.element.style.filter = '';
    }
    if (scale < 1) {
      this.heroContainerElement.style.transform = `scale(${scale})`;
    } else {
      this.heroContainerElement.style.transform = '';
    }
    // no brightness change for firefox and chrome because they suck
    if (brightness < 1 && !(navigator.userAgent.toLowerCase().indexOf('firefox') !== -1 || navigator.userAgent.toLowerCase().indexOf('chrome') !== -1)) {
      this.heroElement.style.filter = `brightness(${brightness})`;
    } else {
      this.heroElement.style.filter = '';
    }
  }

  draw() {
    if (this.isStartAnimationPlayed) {
      if (this.canplaythrough) {
        this._rotate();
      }
    }
  }
}

export default HeroVideo;
