import Component from './Component';

const { HELPER } = window;

class Moreover extends Component {
  constructor(_element) {
    super(_element);

    this.imageElement = _element.querySelector('.moreover__image');
  }

  draw() {
    if (this.elementIsInView()) {
      const scrollingCenterY = HELPER.scrollY + window.innerHeight / 2;
      const y = (scrollingCenterY - this.elementCenterY) * 0.04;
      this.imageElement.style.transform = `translateY(${y / 16}rem)`;
    }
  }
}

Array.from(document.querySelectorAll('.moreover')).forEach((_element) => {
  new Moreover(_element);
});
