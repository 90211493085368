class HeroInfo {
  constructor(_parentInstance, element) {
    this.parentInstance = _parentInstance;
    this.element = element;
    this.timeShow = element.dataset.timeShow;
    this.timeHide = element.dataset.timeHide;
  }

  _show() {
    if (this.parentInstance.video.isStartAnimationPlayed) {
      this.element.classList.add('is-visible');
    }
  }

  _hide() {
    this.element.classList.remove('is-visible');
  }

  draw() {
    const currentTime = this.parentInstance.video.potentialCurrentTime;
    if (this.timeHide > currentTime && currentTime >= this.timeShow) {
      this._show();
    } else {
      this._hide();
    }
  }
}

export default HeroInfo;
