const { HELPER } = window;

class FloatingBoxesItem {
  constructor(_parentInstance, _element) {
    const self = this;
    let position;

    function positionElement() {
      try {
        self.position = JSON.parse(_element.dataset.position); // left, top, depth
        self.scale = 1 - self.position.depth * 0.015;
        const left = self.position.left * 100;
        const top = self.position.top * 100;
        const { depth } = self.position;
        _element.style.transitionDuration = '0s';
        _element.style.left = `calc(${left}% - ${_element.offsetWidth / 2}px)`;
        _element.style.top = `calc(${top}% - ${_element.offsetHeight / 2}px)`;
        // _element.style.filter = `blur(${self.position.depth}px)`;
        _element.style.transform = `scale(${self.scale})`;
        _element.style.zIndex = 10 - depth;
        _element.offsetWidth;
        _element.style.transitionDuration = '';
      } catch (exception) {
        console.info(exception);
      }
    }

    function onMousemove(event) {
      self.mouseX = event.clientX;
      self.mouseY = event.clientY;
    }

    function onTouchmove(event) {
      self.mouseX = event.pageX;
    }

    function onTouchend() {
      self.mouseX = self.parentElement.offsetWidth / 2;
    }

    function onResize() {
      positionElement();
    }

    function onLoad() {
      let loadedImages = 0;
      positionElement();
      _element.removeEventListener('load', onLoad);
      self.isImageLoaded = true;
      _parentInstance.itemInstances.forEach((instance) => {
        instance.isImageLoaded ? loadedImages++ : null;
      });
      if (loadedImages === _parentInstance.itemInstances.length) {
        _parentInstance.itemInstances.forEach((instance) => {
          instance.showElement();
        });
      }
    }

    _element.style.transitionDuration = '0s';
    _element.style.opacity = '0';

    window.addEventListener('mousemove', onMousemove);
    window.addEventListener('touchstart', onTouchmove);
    window.addEventListener('touchmove', onTouchmove);
    window.addEventListener('touchend', onTouchend);
    window.addEventListener('resize', onResize);

    this.parentElement = _parentInstance.element;
    this.element = _element;
    this.imgElement = _element.querySelector('img');
    this.isVisible = false;
    this.isImageLoaded = false;
    this.position = position;
    this.mouseX = this.parentElement.offsetWidth / 2;
    this.mouseY = this.parentElement.offsetHeight / 2;

    if (this.imgElement.complete && this.imgElement.offsetWidth > 0) {
      window.requestAnimationFrame(onLoad);
    } else {
      this.imgElement.addEventListener('load', onLoad);
    }
  }

  showElement() {
    const { element } = this;
    const top = this.position.top * 100;
    const topStart = `calc(${top}% - ${element.offsetHeight / 2 - (10 - this.position.depth) * 5}px)`;
    element.style.transitionDuration = '0s';
    element.style.top = topStart;
    element.offsetWidth;
    element.style.transitionDuration = '1s';
    element.style.opacity = '';
    element.style.top = `calc(${top}% - ${element.offsetHeight / 2}px)`;
    element.offsetWidth;
    element.style.transitionDuration = '';
    this.isVisible = true;
  }

  draw() {
    if (this.isImageLoaded) {
      const strength = 1;
      const elementCenterY = this.parentElement.offsetTop + this.parentElement.offsetHeight / 2;
      const x = (this.mouseX / window.innerWidth - 0.5) * ((5 - this.position.depth) * strength);
      let y = (this.mouseY / window.innerHeight - 0.5) * ((5 - this.position.depth) * strength);
      y += (HELPER.scrollCenterY - elementCenterY) * 0.01 * ((5 - this.position.depth) * strength);
      this.element.style.transform = `scale(${this.scale}) translate(${x}%, ${y}%)`;
    }
  }
}

export default FloatingBoxesItem;
